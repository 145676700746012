<template>
  <div class="registration-step-1-block">
    <div class="login-block-container">
      <div class="reg-step-form">
        <h3>{{ $lang.app.reset_account }}</h3>
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="surname" type="text" autocomplete="off"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="forgotPasswordForm.surname"/>
              <ExtraKyrgyzLetters @change="forgotPasswordForm.surname += $event"/>
            </div>
            <ErrorMessage name="surname" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.email }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="email" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"/>
            </div>
            <ErrorMessage name="email" class="recovery-label-error" />
          </div>
          <div class="login-control">
            <button class="transparent-button" @click="$router.push('/auth/login-parent')">{{ $lang.app.cancel }}</button>
            <button class="blue-button" type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ExtraKyrgyzLetters from '../../components/ExtraKyrgyzLetters.vue'

export default {
  name: 'ForgotPassword',
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      forgotPasswordForm: {
        surname: '',
        email: '',
      },
      schema: yup.object({
        surname: yup.mixed().required(),
        email: yup.string().required().email(),
      }),
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.get('/auth/restore-password/', {
        params: {
          surname: values.surname,
          email: values.email,
        }
      }).then(() => {
        this.$router.push(`/auth/email-success?email=${values.email}`)
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors({
            email: error.response.data.message,
          });
        }
      })
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/auth.css';
</style>